<template>
  <div class="abs">
    <div class="card_item p-3">
      <div class="row m-0 justify-content-center">
        <div class="col-12 text-center px-0 pb-2 border_bottom mb-3 green-text bold">
          Switch Site Region & Currency
        </div>
        <div class="col-12 px-0">
          <div class="row mx-0 align-items-center theAction cursor" @click="switchTo">
            <div class="col-auto p-1">
              <img v-if="otherSite.icon" :src="require('@/assets/images/' + otherSite.icon + '.png')" class="img-fluid p-0 flagicon">
            </div>
            <div class="col px-2 green-text bold">
              {{ otherSite.name }} | {{ otherSite.currency }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['otherSite'],
  methods: {
    switchTo () {
      window.location.href = this.otherSite.url
    }
  }
}

</script>

<style scoped>
.abs {
  position: absolute;
  width: 80vw;
  height: inherit;
  right: -34px;
  left: auto;
  top: inherit;
  z-index: 2;
  background-color: transparent;
  justify-content: center;
  align-items: center;
}
.flagicon {
  max-height: 2.1rem;
}
.card_item {
  background-color: #fff;
  border-radius: 13px;
  box-shadow: rgba(0, 0, 0, 0.09) 5px 5px 10px 0px;
}
.theAction {
  border-radius: 2rem;
}
.theAction:hover {
  background-color: rgba(var(--gold-color-rgb), 0.6);
}
@media (min-width: 576px) {
  .abs {
    width: 47vw;
  }
}
@media (min-width: 768px) {
  .abs {
    width: 35vw;
  }
}
@media (min-width: 992px) {
  .abs {
    width: 600%;
    left: -250%;
  }
}
</style>
